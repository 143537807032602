import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import ReactMarkdown from "react-markdown"
import NewLayout from "../components/new_layout"
import CardPost from "../components/v2023/UI/Card/card-post"
import rehypeRaw from "rehype-raw"
import { getFirstText } from "../utils/gatsby-helpers"
import SEO from "../components/seo"

const UseCasesPage = ({ data, pageContext }) => {
    const page = data.page
    const lang = pageContext.langKey

    const useCases = data.useCases.edges
    
    const baseUrl = lang === "en" ? "" : `/${lang}`

    const offset = 9
    const [cardSize, setCardSize] = useState(offset)
    const [cardListed, setCardListed] = useState(page.blog_posts.slice(0, offset))

    const showMoreCards = () => {
        let size = cardSize

        if (size < page.blog_posts.length) {
        size += offset
        setCardSize(size)
        setCardListed(page.blog_posts.slice(0, size))
        }
    }

    const successCases = {}
    successCases.name = "Casos de éxito"
    successCases.description = "Soluciones adaptadas a cada sector"
    successCases.button = "Saber más"
    successCases.url = "https://www.google.es"
    successCases.image = {}
    successCases.image.alternativeText = "Alternative text"
    successCases.image.localFile = ""
    return (
        <NewLayout
        pageContext={pageContext}
        lang={pageContext?.langKey}
        translates={pageContext?.translates}
        >
        <SEO
            lang={lang}
            title={page.seo.title}
            description={page.seo.meta_description}
            translates={pageContext.translates}
            image={page.seo.image_2.localFile.publicURL}
        />

        <main className="main">
            <div className="main__section main__section--100 white-section brown">
            <div className="container" data-aos="fade-up">
                <h1 lang="es" className="merriweather">
                    {page.title}
                </h1>

                <div className="container__text">
                    <ReactMarkdown
                        children={page.description}
                        rehypePlugins={[rehypeRaw]}
                    />
                </div>

                {/* Con imagen */}
                <div className="grid-lg-3">
                    {useCases.map((useCase, index) => {
                        if (index < 5) {
                        return (
                            <CardPost
                                key={index}
                                image={useCase.node.seo.image_2}
                                lang={lang}
                                name={useCase.node.title}
                                alternativeText={useCase.node.alternativeText}
                                description={useCase.node.description}
                                url={useCase.node.url}
                                showMore="true"
                                />
                            )
                        }
                    })}
                </div>
            </div>

            {/* Sin imagen */}
            <div className="main__section color-section gray gray--noframe">
                <div className="container">
                <div className="grid-lg-3">
                    {cardListed.map((card, index) => {
                    return (
                        <CardPost
                        key={index}
                        image=""
                        lang={lang}
                        name={card.name}
                        description={getFirstText(card.seo.meta_description)}
                        url={`${baseUrl}/blog/${card.url}`}
                        showMore="true"
                        />
                    )
                    })}
                </div>
                {cardListed.length < page.blog_posts.length && (
                    <div className="container__see-more">
                    <button
                        className="button button--xl button--secondary"
                        onClick={showMoreCards}
                    >
                        {page.text_button}
                    </button>
                    </div>
                )}
                </div>
            </div>

            <div className="main__section main__section--100">
                <div className="container">
                    <div className="grid-lg-2">
                        
                        {/* Industrias */}
                        <div className="tile tile--image">
                            <div className="tile__body">
                                <h3>{page.cta_industries.name}</h3>
                                <ReactMarkdown
                                    children={page.cta_industries.description}
                                    rehypePlugins={[rehypeRaw]}
                                />
                                <Link
                                    to={page.cta_industries.url}
                                    className="button button--small"
                                >
                                    {page.cta_industries.button}{" "}
                                    <i className="icon-arrow-right"></i>
                                </Link>
                            </div>
                            <div className="tile__image">
                                <GatsbyImage
                                    alt={page.cta_industries.image.alternativeText}
                                    image={getImage(page.cta_industries.image.localFile)}
                                />
                            </div>
                        </div>

                        {/* Casos de éxito */}
                        <div className="tile tile--image tile--purple">
                            <div className="tile__body">
                                <h3>{page.cta_success_cases.name}</h3>
                                <ReactMarkdown
                                    children={page.cta_success_cases.description}
                                    rehypePlugins={[rehypeRaw]}
                                />
                                <Link
                                    to={page.cta_success_cases.url}
                                    className="button button--small"
                                >
                                    {page.cta_success_cases.button}{" "}
                                    <i className="icon-arrow-right"></i>
                                </Link>
                            </div>
                            <div className="tile__image">
                                <GatsbyImage
                                    alt={page.cta_success_cases.image.alternativeText}
                                    image={getImage(page.cta_success_cases.image.localFile)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</NewLayout>
    )
}

export default UseCasesPage

export const useCasesQuery = graphql`
  query ($langKey: String) {
    page: strapi2024CasosDeUso(locale: { eq: $langKey }) {
      seo {
        title
        meta_description
        image_2 {
          localFile {
              publicURL
          }
        }
      }
      title
      description
      blog_posts {
        seo {
          meta_description
        }
        name
        url
      }
      text_button
      cta_industries {
        name
        description
        button
        url
        blank
        image {
          name
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(
                height: 226
                quality: 100
                placeholder: BLURRED
                formats: [WEBP]
              )
            }
          }
        }
      }
      cta_success_cases {
        name
        description
        button
        url
        blank
        image {
          name
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(
                height: 226
                quality: 100
                placeholder: BLURRED
                formats: [WEBP]
              )
            }
          }
        }
      }
    }
    useCases: allStrapiCasoDeUso(filter: { locale: { eq: $langKey } }) {
      edges {
        node {
          seo {
            image_2 {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 690
                    quality: 100
                    placeholder: BLURRED
                    formats: [WEBP]
                  )
                }
              }
            }
          }
          url
          title
          description
          tag
          main_image {
            name
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                    quality: 100
                    placeholder: BLURRED
                    formats: [WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`
